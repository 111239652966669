.ap-required, .ap-required:focus, .ap-required:active {
  border: 1px solid #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(236, 133, 130, .075);
  box-shadow: inset 0 1px 1px rgba(236, 133, 130, .075);
  /*
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075); */
}
.react-datepicker-wrapper {
  display: block;
}
.react-datepicker__input-container {
  display: block;
}
.react-datepicker-popper {
  z-index: 99;
}
.hidden {
  display: none;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}
.ReactModal__Overlay {
  z-index: 999;
}

@media print{
  /* *,:after,:before{
    color:#000!important;
    text-shadow:none!important;
    background:0 0!important;
    -webkit-box-shadow:none!important;
    box-shadow:none!important
  } */
  /* a,a:visited{text-decoration:underline}a[href]:after{content:" (" attr(href) ")"}abbr[title]:after{content:" (" attr(title) ")"}a[href^="javascript:"]:after,a[href^="#"]:after{content:""}blockquote,pre{border:1px solid #999;page-break-inside:avoid}thead{display:table-header-group}img,tr{page-break-inside:avoid}img{max-width:100%!important}h2,h3,p{orphans:3;widows:3}h2,h3{page-break-after:avoid}.navbar{display:none}.btn>.caret,.dropup>.btn>.caret{border-top-color:#000!important}.label{border:1px solid #000} */
  .table{border-collapse:collapse!important}
  .table td,.table th{background-color:#fff!important}
  .table-bordered td,.table-bordered th{border:0 !important}
}

.w-100 {
  min-width: 100px;
}
.w-140 {
  min-width: 140px;
}
.w-150 {
  min-width: 150px;
}
.w-200 {
  min-width: 200px;
}
.h-30 {
  height: 30px;
}
.light-green {
  background: #a8ffa8
}
.text-left {
  text-align: left !important;
}
